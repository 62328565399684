import { faCloudArrowUp, faComments, faFile, faFilePdf, faHeadset, faTrash, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PageContext } from '../../../context/pageContext';
import clienteAxios from '../../../config/axios';
import Modal from '../../KbPages/layout/Modal';
import { FormattedMessage } from 'react-intl';
import { langContext } from '../../../context/langContext';

function Ticket() {
    const mensajesRef = useRef(null);
    const { lang } = useContext(langContext)
    const {id} = useParams()
	const [content, setContent] = useState('');
	const [userAuthor, setUserAuthor] = useState(0);
	const [ticket, setTicket] = useState({id: 0, messages: []});
    const navigate = useNavigate()
    const [prevFiles, setPrevFiles] = useState([{type: '', url: "", name: ''}]);
	const [files, setFiles] = useState([]);
    const [ online24 ] = useContext(PageContext)
    const [modal, setModal] = useState(false)
    const [imgRut, setImgRut] = useState({link: '', name: ''})

    useEffect(() => {

        if (mensajesRef.current) {
          mensajesRef.current.scrollTop = mensajesRef.current.scrollHeight;
        }
    }, [ticket]); 

    useEffect(() => {
        const consultaApi = async () => {
            try {
                    let localUserData = localStorage.getItem('userData');
                    if (localUserData) {
                        localUserData = JSON.parse(localUserData);
                    } else {
                        const currentURL = window.location.pathname;
                        localStorage.setItem('prevUrl', currentURL)
                        navigate('/login')
                    }
                    
                    const res = await clienteAxios.get(`/api/instance/${online24.id}/service/24ONLINE/ticket/${id}`)
                    if(res.data?.result?.data && res.data?.result?.errors?.length === 0) {
                        if(Array.isArray(localUserData) && localUserData.length !== 0) {
                            //Verificar que el ticket pertenezca al usuario
                            if(parseInt(localUserData[0].UserId) !== parseInt(res.data?.result?.data[0].userId)) navigate('/dashboard/tickets')
                        }

                        setTicket(res.data?.result?.data[0])
                        

                        let messages = res.data?.result?.data[0].messages;
                        if (Array.isArray(messages) && messages.length > 0) {
                            // Obtener el último elemento del arreglo
                            let lastIndex = messages.length - 1;
                            let lastUserId = messages[lastIndex].AUTHOR_ID
                        
                            setUserAuthor(lastUserId);
                        }


                    }

            } catch (error) { console.log(error) }
        }
        if(online24.id && id) {
            consultaApi()
        }
        
    }, [online24, id])

    const onSumitMessage = async (e) => {
        let userData = localStorage.getItem('userData');
        if (userData) {
            userData = JSON.parse(userData);
        } else {
            navigate('/login')
        }
        const ticketReq = {
            ID: ticket.id,
            MESSAGE: content,
            FILES: files
        }
        const nuevoMensaje = { MESSAGE: content, ID: 1, AUTHOR_ID: userAuthor, DATE_CREATE: Date.now(), FILES: [] };

        setTicket(prevTicket => {
            return {
                ...prevTicket,
                messages: [nuevoMensaje, ...prevTicket.messages]
            };
        });

        if (mensajesRef.current) {
            mensajesRef.current.scrollTop = mensajesRef.current.scrollHeight;
        }
        setContent('')
        try {
            console.log(ticketReq);
            const res = await clienteAxios.put(`/api/instance/${online24.id}/service/24ONLINE/ticket`, ticketReq)
            console.log(res.data);
            setFiles([])
            setPrevFiles([{type: '', url: "", name: ''}])
            setTicket(res.data?.result?.data[0])
        } catch (error) {
            console.log(error);
        }
    }
    const reedFiles = async e => {
        try {
          const formData = new FormData()
          formData.append('file', e.target.files[0])
          const res = await clienteAxios.post(`/api/uploadFile/${online24.id}/tickets`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }) 
          if (res.data) {
            const preView = {type: e.target.files[0].type, url: res.data.fileUrl, name: e.target.files[0].name}
            setPrevFiles([...prevFiles, preView])
            setFiles([...files, res.data.fileUrl])
          }
        } catch (error) {
          console.log(error)
        }
    }

    const deleteFileUrl = (prevFile) => {
        const newFiles = files.filter(item => item !== prevFile.url);
        const newPrevFiles = prevFiles.filter(item => item.url !== prevFile.url);
        setFiles(newFiles)
        setPrevFiles(newPrevFiles)
    }

    const toggleModal = (link) => {
        setModal(!modal)
        setImgRut(link)
    }

    const descargarArchivo = (url, nombreArchivo) => {
        window.open(url, '_blank');
    };

    return(
      <div className="dashboard-content">
        
        <div className='ticket display-flex'>
            <FontAwesomeIcon icon={faComments} />
            <p className='no-margin'>#{ticket.id}</p>
            <Link to={`#`}>{ticket.name}</Link>
        </div>

        <div className='margin-0-auto'>

            <div className='ticket-chat' ref={mensajesRef}>
                {ticket.messages ? [...ticket.messages].reverse().map((message, i) => {
                    if(message.MESSAGE || !Array.isArray(message?.FILES)) {
                        if(message.AUTHOR_ID === userAuthor) {
                            return (
                                <div key={i} className='ticket-message' >

                                    <div className='content-message__header'>
                                        <p className=''>{message.DATE_CREATE}, {message.AUTHOR_NAME}</p>
                                    </div>


                                    <div className='content-message'>

                                        <div className='side-message'>
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>

                                        <div className='content-message__body'>
                                            <p className=''>{message.MESSAGE}</p>

                                            {!Array.isArray(message?.FILES) ? (
                                                <div className='attachment' >
                                                    <p className=''>
                                                        <FormattedMessage
                                                            id='ticketAttachments'
                                                            defaultMessage='Attachments'
                                                        />:
                                                    </p>
                                                </div>
                                            ) : null }

                                            {!Array.isArray(message?.FILES) ? (
                                                <div className='bot__buttons' >
                                                    {Object.keys(message?.FILES).map(messageFile => (
                                                        <div key={messageFile} className='admin__files__file' onClick={ message?.FILES[messageFile]?.type === 'image' ? () => toggleModal({link: message?.FILES[messageFile]?.link, name: message?.FILES[messageFile]?.name}) : () => descargarArchivo(message?.FILES[messageFile]?.link, message?.FILES[messageFile]?.name) } >
                                                            {message?.FILES[messageFile]?.type === 'image' ? <img src={message?.FILES[messageFile]?.link} alt='pre'/> : <FontAwesomeIcon icon={faFile} /> }
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null }
                                        </div>
                                    </div>
    
                                </div>
                            )
                        } else {
                            return (
                                <div key={i} className='ticket-message' >
                                    

                                    <div className='content-message__header'>
                                        <p className=''>{message.DATE_CREATE}, {message.AUTHOR_NAME}</p>
                                    </div>
                                    <div className='content-message'>

                                        <div className='side-message'>
                                            <FontAwesomeIcon icon={faHeadset} />
                                        </div>

                                        <div className='content-message__body'>
                                            <p className=''>{message.MESSAGE}</p>

                                            {!Array.isArray(message?.FILES) ? (
                                                <div className='attachment' >
                                                    <p className=''>
                                                        <FormattedMessage
                                                            id='ticketAttachments'
                                                            defaultMessage='Attachments'
                                                        />:
                                                    </p>
                                                </div>
                                            ) : null }

                                            {!Array.isArray(message?.FILES) ? (
                                                <div className='bot__buttons' >
                                                    {Object.keys(message?.FILES).map(messageFile => (
                                                        <div key={messageFile} className='admin__files__file' onClick={ message?.FILES[messageFile]?.type === 'image' ? () => toggleModal({link: message?.FILES[messageFile]?.link, name: message?.FILES[messageFile]?.name}) : () => descargarArchivo(message?.FILES[messageFile]?.link, message?.FILES[messageFile]?.name) } >
                                                            {message?.FILES[messageFile]?.type === 'image' ? <img src={message?.FILES[messageFile]?.link} alt='pre'/> : <FontAwesomeIcon icon={faFile} /> }
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : null }
                                        </div>
                                    </div>
    

                                </div>
                                
                            )
                        }
                    }
                }): null}

            </div>

            <div className='ticket-chat' ref={mensajesRef}>

                <div className='content-message content-message--send' >
                    <textarea
                        className="campo textarea-full m-0"
                        name="question"
                        placeholder={lang.writeQuestion ? lang.writeQuestion : "Write your question"}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                    >
                    </textarea>

                    <button className='boton boton--primario boton--file' type='button' >
                        <input
                            className='hidden'
                            type="file"
                            name="files"
                            onChange={reedFiles}
                        />
                        <FormattedMessage
                            id='uploadFiles'
                            defaultMessage='Upload files'
                        /> <FontAwesomeIcon icon={faCloudArrowUp} />
                    </button>

                    <button className='boton boton--primario' onClick={onSumitMessage} type='button'>
                        <FormattedMessage
                            id='send'
                            defaultMessage='Send'
                        /> 
                    </button>
                    
                    <div className='preview-files'>
                        {prevFiles.map((prevFile, i) => {
                            // eslint-disable-next-line
                            if(prevFile.type == 'image/gif' || prevFile.type == 'image/vnd.ms-photo' || prevFile.type == 'image/jpe' || prevFile.type == 'image/jpg' || prevFile.type == 'image/png' || prevFile.type == 'image/svg+xml' || prevFile.type == 'image/jpeg'  ) {
                                return (
                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                        <img src={prevFile.url} alt='pre'/>
                                        
                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                    </div>
                                )
                                // eslint-disable-next-line
                            } else if(prevFile.type == 'application/pdf')  {
                                return (
                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                        <FontAwesomeIcon icon={faFilePdf} />
                                        
                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                    </div>
                                )
                                
                            } else if(!prevFile.type) {
                                
                                return null
                            } else {
                                return (
                                    <div key={i} className='preview-file' onClick={() => deleteFileUrl(prevFile)}>
                                        <FontAwesomeIcon icon={faFile} />
                                        
                                        <FontAwesomeIcon className="delete-file" icon={faTrash} />
                                    </div>
                                )
                            }
                            
                        })}
                    </div>
                </div>

            </div>


        </div>

        <Modal  
            imgRut={imgRut}
            modal={modal} 
            setModal={setModal}
        />

      </div>
    )
}
export default Ticket
